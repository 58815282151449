
import {
  computed, defineComponent, PropType, toRefs, ref,
} from 'vue';

import { useI18n } from '@/plugins/vue-i18n';

import TestView from '@/components/ui/TestView.vue';
import InstrumentReviewHeader from '@/components/multileveltest/review/InstrumentReviewHeader.vue';
import QuestionImages from '@/components/ui/QuestionImages';
import MultileveltestAnswers from '@/components/multileveltest/question/MultileveltestAnswers.vue';
import { TestCountdown } from '@/components/ui/TestCountdown';
import DialogComponent from '@/components/ui/DialogComponent';

import {
  AnswersComponentContext, DialogActionTypes, IQuestionChoice, QuestionType,
} from '@/domains/multileveltest';
import { IMultileveltestContent } from '@/domains/multileveltest/composables/useMultileveltestPlayer';

export default defineComponent({
  name: 'MultileveltestQuestionView',
  components: {
    TestView,
    InstrumentReviewHeader,
    QuestionImages,
    MultileveltestAnswers,
    TestCountdown,
    DialogComponent,
  },
  props: {
    testName: {
      type: String,
      required: true,
    },
    content: {
      type: Object as PropType<IMultileveltestContent>,
      required: true,
    },
    startedAt: {
      type: String,
      default: '',
    },
    maxSecondsCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { question, answer } = toRefs(props.content);

    const { t } = useI18n();

    const isLoadingBtn = ref<boolean>(false);
    const isTimeFinished = ref<boolean>(false);
    const dialogConfirmation = ref();

    const isTimeUnlimited = computed(() => props.content.answerTimeout === 0);
    const answers = ref<AnswersComponentContext>();
    const choices = computed(() => question.value?.choices || []);
    const isMonoQuestion = computed(() => question.value?.questionType === QuestionType.MONO);
    const description = computed(() => (isMonoQuestion.value
      ? t('MultileveltestQuestion.descriptionMono')
      : t('MultileveltestQuestion.descriptionMulty')));
    const buttonName = computed(() => ((!isTimeFinished.value || isTimeUnlimited.value)
      ? t('MultileveltestQuestion.answer')
      : t('MultileveltestQuestion.next')));

    const updateAnswer = (answersValue: Record<IQuestionChoice['id'], boolean>) => {
      emit('answer:update', {
        answerId: answer.value.id,
        answers: answersValue,
      });
    };

    const next = () => {
      const isHasCheckedRadio = !!(answers.value)?.selectedMono?.id;
      const isHasCheckedCheckbox = !!(answers.value)?.selectedPoly?.length;

      if (!isHasCheckedRadio && !isHasCheckedCheckbox && (!isTimeFinished.value || isTimeUnlimited.value)) {
        dialogConfirmation.value.open();
      } else {
        emit('answer:finish', answer.value.id);
      }
    };

    const dialogAction = (action: DialogActionTypes) => {
      if (action === DialogActionTypes.CANCEL) {
        dialogConfirmation.value.close();
      } else {
        emit('answer:finish', answer.value.id);
        dialogConfirmation.value.close();
      }
    };

    const timerFinished = () => {
      isTimeFinished.value = true;
    };

    const finishTest = () => {
      emit('multileveltest:finish');
    };

    return {
      choices,
      description,
      question,
      isLoadingBtn,
      buttonName,
      answers,
      isTimeFinished,
      isTimeUnlimited,
      dialogConfirmation,
      timerFinished,
      updateAnswer,
      next,
      dialogAction,
      finishTest,
    };
  },
});
