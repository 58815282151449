
import {
  computed, defineComponent, onMounted, ref, toRefs,
} from 'vue';

import { useRouter } from 'vue-router/composables';
import { useMultileveltestPlayer } from '@/domains/multileveltest/composables/useMultileveltestPlayer';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { useAssignment } from '@/domains/assignment/composables';

import MultileveltestQuestionView
  from '@/views/App/Learning/Atoms/multileveltest/MultileveltestQuestionView/MultileveltestQuestionView.vue';

import { AtomType } from '@/domains/atom';
import { IQuestionChoice } from '@/domains/multileveltest';

import Names from '@/plugins/vue-router/routeNames';

export default defineComponent({
  name: 'MultileveltestQuestion',
  components: {
    MultileveltestQuestionView,
  },
  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const { playerSessionId } = toRefs(props);

    const isComponentLoading = ref(true);

    const router = useRouter();

    const {
      isLoading: isMultileveltestLoading,
      content,
      questionStartedAt,
      maxSecondsCount,
      startQuestionAndTimer,
      updateAnswer,
      finishAnswer,
      fetchSession,
    } = useMultileveltestPlayer({ playerSessionId }, context);

    const {
      isLoading: isAssignmentLoading,
      catalogueAtomVersionId,
      fetchAssignmentBySession,
    } = useAssignment({ playerSessionId, atomType: AtomType.MULTILEVELTEST });

    // TODO: в будущем брать атом из ручки fetchAssignmentBySession
    const {
      atom,
      isLoading: isAtomLoading,
      fetchAtom,
    } = useCatalogueAtom({ atomId: catalogueAtomVersionId });

    const isLoading = computed(() => (
      isComponentLoading.value
      || isMultileveltestLoading.value
      || isAssignmentLoading.value
      || isAtomLoading.value));

    const onUpdateAnswerHandler = async (data: {
      answerId: number,
      answers: Record<IQuestionChoice['id'], boolean>,
    }) => {
      const { answerId, answers } = data;
      await updateAnswer(answerId, answers);
    };

    const onFinishAnswerHandler = async (answerId: number) => {
      await finishAnswer(answerId);
      await startQuestionAndTimer();
    };

    const onFinishTestHandler = () => {
      router.push({
        name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_INTRO,
        params: {
          playerSessionId: String(playerSessionId.value),
        },
      });
    };

    onMounted(() => {
      Promise.all([
        fetchSession(),
        fetchAssignmentBySession()
          .then(fetchAtom),
        startQuestionAndTimer(),
      ])
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isComponentLoading.value = false;
        });
    });

    return {
      content,
      isLoading,
      atom,
      questionStartedAt,
      maxSecondsCount,
      onUpdateAnswerHandler,
      onFinishAnswerHandler,
      onFinishTestHandler,
    };
  },
});
